<template>
	<div class="orders-container" v-if="orders">
		<base-font variant="heading" marginReset>
			{{ langs.text }}
		</base-font>
		<div class="current-orders">
			<base-font class="table-header" size="sm">Status aktualnych zamówień</base-font>
			<div class="orders-table" v-if="unCompletedOrders.length > 0">
				<div class="orders-header">
					<div class="order-row even-row">
						<div class="order-id cell">
							{{ langs.orderNumber }}
						</div>
						<div class="order-quantity cell">
							{{ langs.productsQuantity }}
						</div>
						<div class="order-status cell">
							{{ langs.status }}
						</div>
						<div class="order-price cell">
							{{ langs.price }}
						</div>
						<div class="order-details cell">
							{{ langs.details }}
						</div>
					</div>
				</div>
				<div class="orders-content">
					<div class="order-row" v-for="(order, i) in unCompletedOrders" :key="i"
						:class="{ 'even-row': i % 2 !== 0 }">
						<div class="order-id cell">
							{{ order.id }}
						</div>
						<div class="order-quantity cell">
							{{ order.items.length }}
						</div>
						<div class="order-status cell">
							{{ langs.orderStatuses[order.status] }}
						</div>
						<div class="order-price cell">
							<base-price :price="order.amount" />
						</div>
						<div class="order-details cell">
							<base-link to="user-profile"  :params="{ view: userProfileViews['single-order'] }" :query="{id: order.id}" class="order-link">
								<base-svg class="details-icon" name="search" />
								<span>{{ langs.showDetails }}</span>
							</base-link>
						</div>
					</div>
				</div>
			</div>
			<div class="no-actual-orders">
				<base-font variant="small-heading">Brak zamówień</base-font>
			</div>
		</div>
		<div class="historical-orders">
			<base-font class="table-header" size="sm">Historia zamówień</base-font>
			<div class="orders-table" v-if="completedOrders.length > 0">
				<div class="orders-header">
					<div class="order-row even-row">
						<div class="order-id cell">
							{{ langs.orderNumber }}
						</div>
						<div class="order-quantity cell">
							{{ langs.productsQuantity }}
						</div>
						<div class="order-status cell">
							{{ langs.completionDate }}
						</div>
						<div class="order-price cell">
							{{ langs.price }}
						</div>
						<div class="order-details cell">
							{{ langs.details }}
						</div>
					</div>
				</div>
				<div class="orders-content">
					<div class="order-row" v-for="(order, i) in completedOrders" :key="i"
						:class="{ 'even-row': i % 2 !== 0 }">
						<div class="order-id cell">
							{{ order.id }}
						</div>
						<div class="order-quantity cell">
							{{ order.items.length }}
						</div>
						<div class="order-status cell">
							{{ order.dateUpdated | date }}
						</div>
						<div class="order-price cell">
							<base-price :price="order.amount" />
						</div>
						<div class="order-details cell">
							<base-link to="user-profile"  :params="{ view: userProfileViews['single-order'] }"
									:query="{id: order.id}" class="order-link">
								<base-svg class="details-icon" name="search" />
								<span>{{ langs.showDetails }}</span>
							</base-link>
						</div>
					</div>
				</div>
			</div>
			<div class="no-historical-orders">
				<base-font variant="small-heading">Brak zamówień</base-font>
			</div>
		</div>
	</div>
</template>

<script>
import BasePrice from '../../BasePrice/BasePrice'
import { UserProfileViews } from '~/shop/front/core/pages/UserProfile/UserProfile'

export default {
	components: {
		BasePrice
	},
	computed: {
		langs () {
			return this.$app.translator.get('client-profile.orders')
		},
		orders () {
			return this.$app.page.view.orders || []
		},
		areThereNoCompletedOrders () {
			return this.completedOrders.length === 0
		},
		completedOrders () {
			return this.orders.filter(order =>
				['CANCELED', 'SEND', 'REALIZED'].includes(order.status))
		},
		areThereNoUncompletedOrders () {
			return this.unCompletedOrders.length === 0
		},
		unCompletedOrders () {
			return this.orders.filter(order => ['CREATED', 'SEND', 'INPROGRESS', 'PAID_UP'].includes(order.status))
		},
		userProfileViews () {
			return UserProfileViews
		}
	}
}
</script>

<style scoped lang="scss">
.orders-container {
	.current-orders {
		padding-bottom: 2rem;
	}

	.historical-orders {
		border-top: 1px solid #a3a3a3;
	}

	.orders-table {
		display: flex;
		flex-direction: column;
		width: 100%;
		overflow: scroll;

		@include media-breakpoint-up(xl) {
			overflow: hidden;
		}

		.orders-header .cell {
			text-transform: uppercase;
		}

		.orders-header, .orders-content {
			width: fit-content;

			@include media-breakpoint-up(xl) {
				width: 100%;
			}
		}

		.order-row {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: inherit;

			.cell {
				width: 150px;
				padding: 1rem;
				text-align: center;
				&.order-id {
					width: 145px;
					word-wrap: break-word;
				}
				.details-icon {
					width: 30px;
				}
			}

			.order-link {
				display: flex;
				align-items: center;
				justify-content: center;
				width: fit-content;
				margin: auto;
				color: $secondary;
				fill: $secondary;
			}
		}

		.even-row {
			background: #fff;
		}
	}

	::v-deep .table-header {
		padding: 1rem 0;

		@include media-breakpoint-up(xl) {
			padding: 0;
		}
	}
}

</style>
