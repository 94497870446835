<template>
	<span class="price">{{ priceFormated }}</span>
</template>
<script>
import { price } from 'utils/strings'
export default {
	props: {
		price: {
			default: 0,
			type: Number
		}
	},
	computed: {
		priceFormated () {
			return price(this.price)
		}
	}
}
</script>
